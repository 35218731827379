require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");

require('jquery-datepicker');

import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-pl';

datepickerFactory($);
datepickerJAFactory($);

import $ from 'jquery';

import 'bootstrap';

import 'perfect-scrollbar';
import '@coreui/coreui';
import 'select2';

$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("theme", "bootstrap");

require('datatables.net-bs4');
require('datatables.net-select-bs4');
require("turbolinks").start();

function reinit_group_ids_select() {
  var url = $("#participant_group_ids").data('url');

  $.ajax({
    url : url,
    data: $('form').serialize(),
    success: function(response) {
      //console.log('success');
      var data = $.map(response, function (item) {
                    return {
                      text: item[0],
                      id: item[1]
                    }
                  });
      $("#participant_group_ids").empty();
      $("#participant_group_ids").select2({
        width: '100%',
        data: data,
        placeholder: '-- proszę wybrać --',
        allowClear: true,
      });
      $("#participant_group_ids").select2("val","-1");

      $("#participant_reserve_group_id").empty();
      $("#participant_reserve_group_id").select2({
        width: '100%',
        data: data,
        placeholder: '-- proszę wybrać --',
        allowClear: true,
      });
      $("#participant_reserve_group_id").select2("val","-1");
    }
  })
};

$.fn.delayKeyup = function(callback, ms){
  var timer = 0;
  $(this).on('keyup change', function(){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  });
  return $(this);
};

function toggle_participant_radios() {
  if (document.querySelector("input[type=radio][name='participant[technical_editor]']") == null) {
    return;
  }

  if (document.querySelector("input[type=radio][name='participant[technical_editor]']").checked === true) {
    document.getElementById("participant_content_editor_true").disabled = true;
    document.getElementById("participant_content_editor_false").disabled = true;
    document.querySelector("input[type=radio][name='participant[content_editor]']").checked = false

  } else {
    document.getElementById("participant_content_editor_true").disabled = false;
    document.getElementById("participant_content_editor_false").disabled = false;
  }

  if (document.querySelector("input[type=radio][name='participant[content_editor]']").checked === true) {
    document.getElementById("participant_technical_editor_true").disabled = true;
    document.getElementById("participant_technical_editor_false").disabled = true;
    document.querySelector("input[type=radio][name='participant[technical_editor]']").checked = false

  } else {
    document.getElementById("participant_technical_editor_true").disabled = false;
    document.getElementById("participant_technical_editor_false").disabled = false;
  }
}

document.addEventListener('turbolinks:load', function() {
  $(document).ajaxError( function(event, xhr){
    if (xhr.status == 401) {
      window.location = '/';
    }
  });

  if ($.fn.datepicker) {
    $('.datepicker').each(function() {
      var $this = $(this);
      var dataDateFormat = $this.attr('data-dateformat') || 'yy-mm-dd';

      $this.datepicker({
        dateFormat : dataDateFormat,
        firstDay: 1,
        prevText : 'Poprzedni miesiąc',
        nextText : 'Następny miesiąc',
      });
    });
  };

  if ($.fn.select2) {
    $('.select2').select2();
    $('.select2-min-res').each(function() {
      var $this = $(this);
      var width = $this.attr('data-select-width') || '100%';
      var tags = $this.attr('tags') || false;

      $this.select2({
        minimumResultsForSearch: -1,
        tags: tags,
        width : width,
        theme: "bootstrap"
      });
    });
  };

  // formularz zgłoszeniowy

  $("#participant_group_ids").select2({
    width: '100%',
    placeholder: '-- proszę wybrać --',
    allowClear: true,
  });

  $("#participant_reserve_group_id").select2({
    width: '100%',
    placeholder: '-- proszę wybrać --',
    allowClear: true,
  });

  toggle_participant_radios();

  $("input[type=radio][name='participant[technical_editor]']").change(function() {
    toggle_participant_radios();
    reinit_group_ids_select();
  });

  $("input[type=radio][name='participant[content_editor]']").change(function() {
    toggle_participant_radios();
    reinit_group_ids_select();
  });

  $("input[type=radio][name='participant[accessibility_coordinator]']").change(function() {
    toggle_participant_radios();
    reinit_group_ids_select();
  });

  $(".alert-success, .alert-info").fadeTo(2000, 500).slideUp(500, function(){
      $(".alert-success, .alert-info").slideUp(500);
  });

  var i_contact_dt_columns = [
    {'data': 'kod_kpo', 'width': '', 'title': 'Kod KPO', 'width': '80px', 'orderable': false },
    {'data': 'kod_up', 'width': '', 'title': 'Kod UP', 'width': '80px', 'orderable': false },
    {'data': 'office', 'width': '', 'title': 'Instytucja', 'orderable': false },
    {'data': 'state', 'width': '', 'title': 'Województwo', 'orderable': false },
    {'data': 'participants_count', 'title': 'Liczba uczestników','width': '160px' },
    {'data': 'email', 'width': '', 'title': 'Email', 'orderable': false },
    {'data': 'coordinator_name', 'width': '', 'title': 'Os. zgłaszająca', 'orderable': false },
    {'data': 'coordinator_phone', 'width': '', 'title': 'Telefon os. zgł.', 'orderable': false },
    {'data': 'coordinator_email', 'width': '', 'title': 'Email os. zgł.', 'orderable': false },
    {'data': 'invitation_status', 'title': 'Zapr.', 'width': '80px', 'orderable': false },
    //{'data': 'technical_editors_count', 'title': 'RT', 'width': '40px' },
    //{'data': 'content_editors_count', 'title': 'RM', 'width': '40px' },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px', 'orderable': false }
  ]

  var ii_contact_dt_columns = [
    {'data': 'office', 'width': '', 'title': 'Instytucja', 'orderable': false },
    {'data': 'email', 'width': '', 'title': 'Email', 'orderable': false },
    {'data': 'invitation_status', 'title': 'Zapr.', 'width': '80px', 'orderable': false },
    //{'data': 'accessibility_coordinators_count', 'title': 'K', 'width': '40px' },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px', 'orderable': false }
  ]

  var office_dt_columns = [
    {'data': 'kod_kpo', 'width': '', 'title': 'Kod KPO', 'width': '80px', 'orderable': false },
    {'data': 'kod_up', 'width': '', 'title': 'Kod UP', 'width': '80px', 'orderable': false },
    {'data': 'name', 'width': '', 'title': 'Instytucja', 'orderable': false },
    {'data': 'state', 'width': '', 'title': 'Województwo', 'orderable': false },
    {'data': 'participants_count', 'title': 'Liczba uczestników','width': '160px' },
    {'data': 'coordinator_name', 'width': '', 'title': 'Os. zgłaszająca', 'orderable': false },
    {'data': 'coordinator_phone', 'width': '', 'title': 'Telefon os. zgł.', 'orderable': false },
    {'data': 'coordinator_email', 'width': '', 'title': 'Email os. zgł.', 'orderable': false },
    {'data': 'invitation_status', 'title': 'Zapr.', 'width': '80px', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px', 'orderable': false }
  ]

  var group_dt_columns = [
    {'data': 'code', 'width': '', 'title': 'Kod', 'orderable': false },
    {'data': 'city', 'width': '', 'title': 'Miasto', 'orderable': false },
    {'data': 'state_name', 'width': '', 'title': 'Województwo', 'orderable': false },
    {'data': 'place', 'width': '', 'title': 'Lokalizacja' },
    {'data': 'start_date', 'width': '150px', 'title': 'D. rozpoczęcia', 'orderable': false},
    {'data': 'end_date', 'width': '150px', 'title': 'D. zakończenia', 'orderable': false},
    {'data': 'participants_classified_count', 'width': '120px', 'title': 'Zakwalifikowani'},
    {'data': 'participants_reserved_count', 'width': '90px', 'title': 'Rezerwowi'},
    {'data': 'status', 'width': '130px', 'title': 'Status'},
    // {'data': 'comment', 'width': 'auto', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var participant_dt_columns = [
    {'data': 'full_name', 'width': '', 'title': 'Uczestnik', 'orderable': false  },
    {'data': 'phone', 'width': '', 'title': 'Nr.tel.', 'orderable': false  },
    {'data': 'created_at', 'width': '150px', 'title': 'D.zgłoszenia', 'orderable': false  },
    {'data': 'group_names', 'width': '', 'title': 'Gr.wybrana', 'orderable': false  },
    //{'data': 'reserve_group_names', 'width': '', 'title': 'Gr.rezer.', 'orderable': false  },
    //{'data': 'civil_service', 'width': '30px', 'title': 'SC', 'orderable': false  },
    //{'data': 'reserve', 'width': '40px', 'title': 'Rez.', 'orderable': false  },
    //{'data': 'reserve_reason', 'width': '', 'title': 'Powód', 'orderable': false  },
    {'data': 'accomodation', 'width': '60px', 'title': 'Nocleg', 'orderable': false  },
    {'data': 'catering_comment', 'width': '', 'title': 'Wyżywienie', 'orderable': false },
    //{'data': 'disability_comment', 'width': '', 'title': 'Potrzeby', 'orderable': false },
    {'data': 'confirmation_sent', 'width': '60px', 'title': 'Zapr.', 'orderable': false},
    {'data': 'sl_completed', 'width': '60px', 'title': 'SL', 'orderable': false},
    {'data': 'reminder_sent', 'width': '60px', 'title': 'Przyp.', 'orderable': false},
    //{'data': 'attachments', 'width': '', 'title': 'Dokumenty', 'orderable': false },
    {'data': 'status', 'width': '80px', 'title': 'Status', 'orderable': false },
    //{'data': 'sl_status', 'width': '80px', 'title': 'Status SL', 'orderable': false },
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var i_classified_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'status', 'width': '', 'title': 'Status'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var i_reserved_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var ii_classified_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'status', 'width': '', 'title': 'Status'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var ii_reserved_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var iii_classified_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'status', 'width': '', 'title': 'Status'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var iii_reserved_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var iv_classified_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'status', 'width': '', 'title': 'Status'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var iv_reserved_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var v_classified_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'status', 'width': '', 'title': 'Status'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var v_reserved_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var vi_classified_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'status', 'width': '', 'title': 'Status'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  var vi_reserved_participant_dt_columns = [
    {'data': 'lp', 'width': '50px', 'title': 'LP' },
    {'data': 'full_name', 'width': '150px', 'title': 'Uczestnik' },
    {'data': 'office_name', 'width': 'auto', 'title': 'Instytucja' },
    //{'data': 'civil_service', 'title': 'SC', 'width': '30px' },
    //{'data': 'technical_editor', 'title': 'RT', 'width': '30px' },
    //{'data': 'content_editor', 'title': 'RM', 'width': '30px' },
    {'data': 'created_at', 'width': '', 'title': 'D. zgłoszenia'},
    {'data': 'comment', 'width': '', 'title': 'Komentarz', 'orderable': false },
    {'data': 'action_links', 'title': 'Akcje', 'class': 'dt-nowrap', 'width': '100px','orderable': false }
  ]

  if ($('#participants_dt_wrapper').length == 0) {
    var f_data;
    var participants_dt = $('#participants_dt').dataTable(
      {
        'pagingType': "simple_numbers",
        'dom': 'tip',
        'columns': participant_dt_columns,
        'serverSide': true,
        'aaSorting': [[ 0, 'asc' ]],
        'bSort': false,
        'bPaginate': true,
        'iDisplayLength': 15,
        'bAutoWidth': false,
        'stateSave': true,
        'stateSaveParams': function(settings, data) {
          participant_dt_columns.forEach( function(col) {
            data['f_'+col.data] = $('#f_'+col.data).val();
          });
        },
        'stateLoadParams': function(settings, data) {
          f_data = data;
        },
        'select': false,
        'language': {
          "processing":     "Przetwarzanie...",
          "search":         "Szukaj",
          "lengthMenu":     "Pokaż _MENU_ pozycji",
          "info":           "Rekordy _START_ - _END_ z _TOTAL_",
          "infoEmpty":      "Pozycji 0 z 0 dostępnych",
          "infoFiltered":   "",
          "infoPostFix":    "",
          "loadingRecords": "Wczytywanie...",
          "zeroRecords":    "Brak uczestników",
          "emptyTable":     "Brak danych",
          "paginate": {
              "first":      "Pierwsza",
              "previous":   "Poprzednia",
              "next":       "Następna",
              "last":       "Ostatnia"
          },
          "aria": {
              "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
              "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
          },
          "buttons":{
              "copyTitle": "Kopiuj",
              "copySuccess":{
                  "_": "Skopiowano %d rekordów",
                  "1": "kopiuj 1 rekord"
              }
          }
        },
        'fnServerData': function( sSource, aoData, fnCallback, oSettings ) {
          //console.log(aoData);

          if ($('#participants_dt div.head-down').length == 0){
            $('#participants_dt thead th').each(function (idx, th) {
                var col_name = participant_dt_columns[idx].data;
                var input;
                if (col_name == 'action_links' || col_name == 'attachments'){
                  input = '&nbsp;';
                } else if (col_name == 'created_at') {
                  input = '<input type="date" name="filters['+col_name+']" class="form-control" placeholder="" '+
                    'id="f_'+col_name+'" style="width:'+participant_dt_columns[idx].width+'" />';
                } else if (['accomodation','reserve','confirmation_sent', 'reminder_sent', 'sl_completed'].includes(col_name)) {
                  input = '<select id="f_'+col_name+'" name="filters['+col_name+']">' +
                    '<option value=""></option><option value="tak">tak</option><option value="nie">nie</option></select>';
                } else if (['status', 'sl_status'].includes(col_name)) {
                  input = '<select id="f_'+col_name+'" name="filters['+col_name+']">' +
                    '<option value=""></option><option value="pending">brak formularza</option><option value="attached">formularz online</option><option value="rejected">formularz do zmiany</option><option value="verified">zweryfikowany</option><option value="accepted">zaakceptowany</option><option value="cancelled">rezygnacja</option></select>';
                } else if (participant_dt_columns[idx].width != '') {
                  input = '<input type="text" id="f_'+col_name+'" name="filters['+col_name+']" style="width:'+participant_dt_columns[idx].width+'" />';
                } else {
                  input = '<input type="text" id="f_'+col_name+'" name="filters['+col_name+']" style="width: 100%" />';
                }
                $(this).html('<div>'+$(this).text()+'</div><div class="head-down">'+input+'</div>');

                var col_value = "";
                if (f_data != undefined) {
                  col_value = f_data["f_"+col_name];
                };
                $('#f_'+col_name).val(col_value);

                //$('input, select', this).on('keyup change', function () {
                  //if ($('#participants_dt').DataTable().search() !== this.value) {
                    //$('#participants_dt').DataTable().search(this.value).draw();
                  //}
                //});
                $('input, select', this).delayKeyup(function(){
                  $('#participants_dt').DataTable().ajax.reload(null, false);
                }, 500);
            });
          }

          var r = '';
          r += "length=" + oSettings._iDisplayLength;
          r += "&page=" + parseInt($('#participants_dt').DataTable().page.info().page + 1);
          r += "&start=" + parseInt($('#participants_dt').DataTable().page.info().start + 1);
          r += "&order_dir=" + $('#participants_dt').dataTable().fnSettings().aaSorting[0][1]
          r += "&order=" + participant_dt_columns[$('#participants_dt').dataTable().fnSettings().aaSorting[0][0]].data;

          $('#participants_dt thead input, #participants_dt thead select').each(function() {
            if ($(this).val() != "") {
              r += '&'+$(this).attr('name')+'='+$(this).val();
            }
          } );

          oSettings.jqXHR = $.getJSON(
            sSource,
            r,
            function( data ) {
              fnCallback(data);
              bind_mail_confirmation();
              bindEvents();
            },
            'json'
          )
        }
      }
    );
  };

  if ($('#groups_dt_wrapper').length == 0) {
    var g_data;
    $('#groups_dt').dataTable(
      {
        'pagingType': "simple_numbers",
        'dom': 'tip',
        'columns': group_dt_columns,
        'serverSide': true,
        'aaSorting': [[ 0, 'asc' ]],
        'bSort': false,
        'bPaginate': true,
        'iDisplayLength': 15,
        'bAutoWidth': false,
        'stateSave': true,
        'stateSaveParams': function(settings, data) {
          group_dt_columns.forEach( function(col) {
            data['g_'+col.data] = $('#g_'+col.data).val();
          });
        },
        'stateLoadParams': function(settings, data) {
          g_data = data;
        },
        'language': {
          "processing":     "Przetwarzanie...",
          "search":         "Szukaj",
          "lengthMenu":     "Pokaż _MENU_ pozycji",
          "info":           "Rekordy _START_ - _END_ z _TOTAL_",
          "infoEmpty":      "Pozycji 0 z 0 dostępnych",
          "infoFiltered":   "",
          "infoPostFix":    "",
          "loadingRecords": "Wczytywanie...",
          "zeroRecords":    "Nie znaleziono pasujących rekordów",
          "emptyTable":     "Brak danych",
          "paginate": {
              "first":      "Pierwsza",
              "previous":   "Poprzednia",
              "next":       "Następna",
              "last":       "Ostatnia"
          },
          "aria": {
              "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
              "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
          },
          "buttons":{
              "copyTitle": "Kopiuj",
              "copySuccess":{
                  "_": "Skopiowano %d rekordów",
                  "1": "kopiuj 1 rekord"
              }
          }
        },
        'fnServerData': function( sSource, aoData, fnCallback, oSettings ) {
          if ($('#groups_dt div.head-down').length == 0){
            $('#groups_dt thead th').each(function (idx, th) {
              var col_name = group_dt_columns[idx].data;
              var input;
              if (col_name == 'action_links'){
                input = '&nbsp;';
              } else if (['status'].includes(col_name)) {
                input = '<select id="g_'+col_name+'" name="filters['+col_name+']">' +
                  '<option value=""></option><option value="open">otwarta</option><option value="closed">zakmnięta</option></select>';
              } else if (col_name == 'start_date' || col_name == 'end_date') {
                input = '<input type="date" name="filters['+col_name+']" class="form-control" placeholder="" '+
                  'id="g_'+col_name+'" style="width:'+group_dt_columns[idx].width+'" />';
              } else if (group_dt_columns[idx].width != '') {
                input = '<input type="text" id="g_'+col_name+'" name="filters['+col_name+']" style="width:'+group_dt_columns[idx].width+'" />';
              } else {
                input = '<input type="text" id="g_'+col_name+'" name="filters['+col_name+']" style="width: 100%" />';
              }
              $(this).html('<div>'+$(this).text()+'</div><div class="head-down">'+input+'</div>');

              var col_value = "";
              if (g_data != undefined) {
                col_value = g_data["g_"+col_name];
              };
              $('#g_'+col_name).val(col_value);

              //$('input, select', this).on('keyup change', function () {
                //if ($('#groups_dt').DataTable().search() !== this.value) {
                  //$('#groups_dt').DataTable().search(this.value).draw();
                //}
              //});
              $('input, select', this).delayKeyup(function(){
                $('#groups_dt').DataTable().ajax.reload(null, false);
              }, 500);
            });
          }
          var r = '';

          r += "length=" + oSettings._iDisplayLength;
          r += "&page=" + parseInt($('#groups_dt').DataTable().page.info().page + 1);
          r += "&start=" + parseInt($('#groups_dt').DataTable().page.info().start + 1);
          r += "&order_dir=" + $('#groups_dt').dataTable().fnSettings().aaSorting[0][1]
          r += "&order=" + group_dt_columns[$('#groups_dt').dataTable().fnSettings().aaSorting[0][0]].data;

          $('#groups_dt thead input, #groups_dt thead select').each(function() {
            if ($(this).val() != "") {
              r += '&'+$(this).attr('name')+'='+$(this).val();
            }
          } );

          oSettings.jqXHR = $.getJSON(
            sSource,
            r,
            function( data ) {
              fnCallback(data);
              bindEvents();
            },
            'json'
          )
        }
      }
    );
  };

  if ($('#contacts_dt_wrapper').length == 0 && $('#contacts_dt').length > 0) {
    var project_code = document.getElementById('contacts_dt').dataset['projectId'];
    var columns = eval(project_code + "_contact_dt_columns");
    var c_data;
    var contacts_dt = $('#contacts_dt').dataTable(
      {
        'pagingType': "simple_numbers",
        'dom': 'tip',
        'columns': columns,
        'serverSide': true,
        'aaSorting': [[ 0, 'asc' ]],
        'bSort': false,
        'bPaginate': true,
        'iDisplayLength': 15,
        'bAutoWidth': false,
        'stateSave': true,
        'stateSaveParams': function(settings, data) {
          columns.forEach( function(col) {
            data['c_'+col.data] = $('#c_'+col.data).val();
          });
        },
        'stateLoadParams': function(settings, data) {
          c_data = data;
        },
        'select': false,
        'language': {
          "processing":     "Przetwarzanie...",
          "search":         "Szukaj",
          "lengthMenu":     "Pokaż _MENU_ pozycji",
          "info":           "Rekordy _START_ - _END_ z _TOTAL_",
          "infoEmpty":      "Pozycji 0 z 0 dostępnych",
          "infoFiltered":   "",
          "infoPostFix":    "",
          "loadingRecords": "Wczytywanie...",
          "zeroRecords":    "Nie znaleziono pasujących rekordów",
          "emptyTable":     "Brak danych",
          "paginate": {
              "first":      "Pierwsza",
              "previous":   "Poprzednia",
              "next":       "Następna",
              "last":       "Ostatnia"
          },
          "aria": {
              "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
              "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
          },
          "buttons":{
              "copyTitle": "Kopiuj",
              "copySuccess":{
                  "_": "Skopiowano %d rekordów",
                  "1": "kopiuj 1 rekord"
              }
          }
        },
        'fnServerData': function( sSource, aoData, fnCallback, oSettings ) {
          //console.log(aoData);
          if ($('#contacts_dt div.head-down').length == 0){
            $('#contacts_dt thead th').each(function (idx, th) {
              var col_name = columns[idx].data;
              var input;
              if (col_name == 'action_links'){
                input = '&nbsp;';
              } else if (columns[idx].width != '') {
                input = '<input type="text" id="c_'+col_name+'" name="filters['+col_name+']" style="width:'+columns[idx].width+'" />';
              } else {
                input = '<input type="text" id="c_'+col_name+'" name="filters['+col_name+']" style="width: 100%" />';
              }
              $(this).html('<div>'+$(this).text()+'</div><div class="head-down">'+input+'</div>');

              var col_value = "";
              if (c_data != undefined) {
                col_value = c_data["c_"+col_name];
              };
              $('#c_'+col_name).val(col_value);

              //$('input, select', this).on('keyup change', function () {
                //if ($('#contacts_dt').DataTable().search() !== this.value) {
                  //$('#contacts_dt').DataTable().search(this.value).draw();
                //}
              //});
              $('input, select', this).delayKeyup(function(){
                $('#contacts_dt').DataTable().ajax.reload(null, false);
              }, 500);
            });
          }
          var r = '';

          r += "length=" + oSettings._iDisplayLength;
          r += "&page=" + parseInt($('#contacts_dt').DataTable().page.info().page + 1);
          r += "&start=" + parseInt($('#contacts_dt').DataTable().page.info().start + 1);
          r += "&order_dir=" + $('#contacts_dt').dataTable().fnSettings().aaSorting[0][1]
          r += "&order=" + columns[$('#contacts_dt').dataTable().fnSettings().aaSorting[0][0]].data;

          $('#contacts_dt thead input, #contacts_dt thead select').each(function() {
            if ($(this).val() != "") {
              r += '&'+$(this).attr('name')+'='+$(this).val();
            }
          } );

          oSettings.jqXHR = $.getJSON(
            sSource,
            r,
            function( data ) {
              fnCallback(data);
              bindEvents;
            },
            'json'
          )
        }
      }
    );
  };

  if ($('#offices_dt_wrapper').length == 0 && $('#offices_dt').length > 0) {
    var columns = office_dt_columns;
    var o_data;
    var offices_dt = $('#offices_dt').dataTable(
      {
        'pagingType': "simple_numbers",
        'dom': 'tip',
        'columns': columns,
        'serverSide': true,
        'aaSorting': [[ 0, 'asc' ]],
        'bSort': false,
        'bPaginate': true,
        'iDisplayLength': 15,
        'bAutoWidth': false,
        'stateSave': true,
        'stateSaveParams': function(settings, data) {
          columns.forEach( function(col) {
            data['o_'+col.data] = $('#o_'+col.data).val();
          });
        },
        'stateLoadParams': function(settings, data) {
          o_data = data;
        },
        'select': false,
        'language': {
          "processing":     "Przetwarzanie...",
          "search":         "Szukaj",
          "lengthMenu":     "Pokaż _MENU_ pozycji",
          "info":           "Rekordy _START_ - _END_ z _TOTAL_",
          "infoEmpty":      "Pozycji 0 z 0 dostępnych",
          "infoFiltered":   "",
          "infoPostFix":    "",
          "loadingRecords": "Wczytywanie...",
          "zeroRecords":    "Nie znaleziono pasujących rekordów",
          "emptyTable":     "Brak danych",
          "paginate": {
              "first":      "Pierwsza",
              "previous":   "Poprzednia",
              "next":       "Następna",
              "last":       "Ostatnia"
          },
          "aria": {
              "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
              "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
          },
          "buttons":{
              "copyTitle": "Kopiuj",
              "copySuccess":{
                  "_": "Skopiowano %d rekordów",
                  "1": "kopiuj 1 rekord"
              }
          }
        },
        'fnServerData': function( sSource, aoData, fnCallback, oSettings ) {
          //console.log(aoData);
          if ($('#offices_dt div.head-down').length == 0){
            $('#offices_dt thead th').each(function (idx, th) {
              var col_name = columns[idx].data;
              var input;
              if (col_name == 'action_links'){
                input = '&nbsp;';
              } else if (['invitation_status'].includes(col_name)) {
                input = '<select id="o_'+col_name+'" name="filters['+col_name+']">' +
                  '<option value=""></option><option value="tak">tak</option><option value="nie">nie</option></select>';
              } else if (columns[idx].width != '') {
                input = '<input type="text" id="o_'+col_name+'" name="filters['+col_name+']" style="width:'+columns[idx].width+'" />';
              } else {
                input = '<input type="text" id="o_'+col_name+'" name="filters['+col_name+']" style="width: 100%" />';
              }
              $(this).html('<div>'+$(this).text()+'</div><div class="head-down">'+input+'</div>');

              var col_value = "";
              if (o_data != undefined) {
                col_value = o_data["o_"+col_name];
              };
              $('#o_'+col_name).val(col_value);

              //$('input, select', this).on('keyup change', function () {
                //if ($('#offices_dt').DataTable().search() !== this.value) {
                  //$('#offices_dt').DataTable().search(this.value).draw();
                //}
              //});
              $('input, select', this).delayKeyup(function(){
                $('#offices_dt').DataTable().ajax.reload(null, false);
              }, 500);
            });
          }
          var r = '';

          r += "length=" + oSettings._iDisplayLength;
          r += "&page=" + parseInt($('#offices_dt').DataTable().page.info().page + 1);
          r += "&start=" + parseInt($('#offices_dt').DataTable().page.info().start + 1);
          r += "&order_dir=" + $('#offices_dt').dataTable().fnSettings().aaSorting[0][1]
          r += "&order=" + columns[$('#offices_dt').dataTable().fnSettings().aaSorting[0][0]].data;

          $('#offices_dt thead input, #offices_dt thead select').each(function() {
            if ($(this).val() != "") {
              r += '&'+$(this).attr('name')+'='+$(this).val();
            }
          } );

          oSettings.jqXHR = $.getJSON(
            sSource,
            r,
            function( data ) {
              fnCallback(data);
              bind_mail_invitation();
              bindEvents;
            },
            'json'
          )
        }
      }
    );
  };

  if ($('#classified_participants_dt_wrapper').length == 0 && $('#classified_participants_dt').length > 0) {
    var project_code = document.getElementById('classified_participants_dt').dataset['projectId'];
    var columns = eval(project_code + "_classified_participant_dt_columns");
    var dataTable = $('#classified_participants_dt').dataTable(
      {
        'sAjaxSource': $('#classified_participants_dt').data('url'),
        "dom": "",
        'pagingType': "simple_numbers",
        "lengthChange": false,
        'columns': columns,
        'serverSide': true,
        'aaSorting': [[ 0, 'asc' ]],
        'bSort': false,
        'bPaginate': false,
        'info': false,
        'searching': false,
        'iDisplayLength': 50,
        'bAutoWidth': false,
        'stateSave': true,
        'language': {
          "processing":     "Przetwarzanie...",
          "search":         "Szukaj",
          "lengthMenu":     "Pokaż _MENU_ pozycji",
          "info":           "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
          "infoEmpty":      "Pozycji 0 z 0 dostępnych",
          "infoFiltered":   "(filtrowanie spośród _MAX_ dostępnych pozycji)",
          "infoPostFix":    "",
          "loadingRecords": "Wczytywanie...",
          "zeroRecords":    "Brak uczestników",
          "emptyTable":     "Brak danych",
          "paginate": {
              "first":      "Pierwsza",
              "previous":   "Poprzednia",
              "next":       "Następna",
              "last":       "Ostatnia"
          },
          "aria": {
              "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
              "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
          },
          "buttons":{
              "copyTitle": "Kopiuj",
              "copySuccess":{
                  "_": "Skopiowano %d rekordów",
                  "1": "kopiuj 1 rekord"
              }
          }
        },
        'fnServerData': function( sSource, aoData, fnCallback, oSettings ) {
          var r = '';

          r = "page=" + parseInt($('#classified_participants_dt').DataTable().page.info().page + 1);
          r += "&start=" + parseInt($('#classified_participants_dt').DataTable().page.info().start + 1);
          r += "&order_dir=" + $('#classified_participants_dt').dataTable().fnSettings().aaSorting[0][1];
          r += "&order=" + columns[$('#classified_participants_dt').dataTable().fnSettings().aaSorting[0][0]].data;

          oSettings.jqXHR = $.getJSON(
            sSource,
            r,
            function( data ) {
              fnCallback(data);
              bind_mail_confirmation();
              bind_ulock_participant_data();
              bind_remove_test();
            },
            'json'
          )
        },
        "initComplete": function(settings, json) {
          bindEvents();
        }
      }
    );
  };

  if ($('#reserved_participants_dt_wrapper').length == 0 && $('#reserved_participants_dt').length > 0) {
    var project_code = document.getElementById('reserved_participants_dt').dataset['projectId'];
    var columns = eval(project_code + "_reserved_participant_dt_columns");
    var dataTable = $('#reserved_participants_dt').dataTable(
      {
        'sAjaxSource': $('#reserved_participants_dt').data('url'),
        "dom": "",
        'pagingType': "simple_numbers",
        "lengthChange": false,
        'columns': columns,
        'serverSide': true,
        'aaSorting': [[ 0, 'asc' ]],
        'bSort': false,
        'bPaginate': false,
        'info': false,
        'searching': false,
        'iDisplayLength': 50,
        'bAutoWidth': false,
        'stateSave': true,
        'language': {
          "processing":     "Przetwarzanie...",
          "search":         "Szukaj",
          "lengthMenu":     "Pokaż _MENU_ pozycji",
          "info":           "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
          "infoEmpty":      "Pozycji 0 z 0 dostępnych",
          "infoFiltered":   "(filtrowanie spośród _MAX_ dostępnych pozycji)",
          "infoPostFix":    "",
          "loadingRecords": "Wczytywanie...",
          "zeroRecords":    "Brak uczestników",
          "emptyTable":     "Brak danych",
          "paginate": {
              "first":      "Pierwsza",
              "previous":   "Poprzednia",
              "next":       "Następna",
              "last":       "Ostatnia"
          },
          "aria": {
              "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
              "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
          },
          "buttons":{
              "copyTitle": "Kopiuj",
              "copySuccess":{
                  "_": "Skopiowano %d rekordów",
                  "1": "kopiuj 1 rekord"
              }
          }
        },
        'fnServerData': function( sSource, aoData, fnCallback, oSettings ) {
          var r = '';

          r = "page=" + parseInt($('#reserved_participants_dt').DataTable().page.info().page + 1);
          r += "&start=" + parseInt($('#reserved_participants_dt').DataTable().page.info().start + 1);
          r += "&order_dir=" + $('#reserved_participants_dt').dataTable().fnSettings().aaSorting[0][1];
          r += "&order=" + columns[$('#reserved_participants_dt').dataTable().fnSettings().aaSorting[0][0]].data;

          oSettings.jqXHR = $.getJSON(
            sSource,
            r,
            function( data ) {
              fnCallback(data)
            },
            'json'
          )
        },
        "initComplete": function(settings, json) {
          bindEvents();
        }
      }
    );
  };

  function bind_mail_confirmation() {
    $('a.mail_confirmation').unbind('ajax:success').bind('ajax:success', function(e) {
      $("#classified_participants_dt").DataTable().ajax.reload(null, false);
      $("#participants_dt").DataTable().ajax.reload(null, false);
    });
    $('a.mail_certificate_form').unbind('ajax:success').bind('ajax:success', function(e) {
      $("#classified_participants_dt").DataTable().ajax.reload(null, false);
    });
  }

  function bind_mail_invitation() {
    $('a.mail_invitation').unbind('ajax:success').bind('ajax:success', function(e) {
      $("#offices_dt").DataTable().ajax.reload(null, false);
    });
  }

  function bind_ulock_participant_data() {
    $('a.unlock_participant_data').unbind('ajax:success').bind('ajax:success', function(e) {
      $("#classified_participants_dt").DataTable().ajax.reload(null, false);
    });
    $('a.clear_participant').unbind('ajax:success').bind('ajax:success', function(e) {
      $("#participants_dt").DataTable().ajax.reload(null, false);
    });
  }

  function bind_remove_test() {
    $('a.remove_test').unbind('ajax:success').bind('ajax:success', function(e) {
      $("#classified_participants_dt").DataTable().ajax.reload(null, false);
    });
  }

  function bindEvents() {
    $('a.group-change').on('click', function(e){
      $('#groupChangeModal').modal('show').find('.modal-body').load($(this).attr('href'), function (){
        $('.select2').select2();
      });

      //$('#patientNoteModal').modal('show').find('.modal-body').html(response);
      //$('.patient-note-form .cancel').click(
      //  function(e) {
      //    $('#patientNoteModal').modal('toggle');
      //    return false;
      //  }
      //);

      //$('.patient-note-form ').on('ajax:success', function(e, data, status, xhr){
      //  $('#patientNoteModal').modal('toggle');
      //  $('#patient_notes_dt').DataTable().ajax.reload(null, false);
      //}).on('ajax:error',function(event){
      //  alert(event.detail[0].errors.join("\n"));
      //});
      e.preventDefault();
    });

    $('a.status-change').on('click', function(e){
      $('#statusChangeModal').modal('show').find('.modal-body').load($(this).attr('href'), function (){
        $('.select2').select2();
        $('.status-change-modal-form').on('ajax:success', function(e, data, status, xhr){
          $('#statusChangeModal').modal('toggle');
          $('#participants_dt').DataTable().ajax.reload(null, false);
        })
      });
      e.preventDefault();
    });

    $('a.status-sl-change').on('click', function(e){
      $('#statusSlChangeModal').modal('show').find('.modal-body').load($(this).attr('href'), function (){
        $('.select2').select2();
        $('.status-sl-change-modal-form').on('ajax:success', function(e, data, status, xhr){
          $('#statusSlChangeModal').modal('toggle');
          $('#participants_dt').DataTable().ajax.reload(null, false);
        })
      });
      e.preventDefault();
    });

    $('a.comment-change').on('click', function(e){
      $('#commentChangeModal').modal('show').find('.modal-body').load($(this).attr('href'), function (){
        $('.select2').select2();
        $('.comment-change-modal-form').on('ajax:success', function(e, data, status, xhr){
          $('#commentChangeModal').modal('toggle');
          $('#participants_dt').DataTable().ajax.reload(null, false);
        })
      });
      e.preventDefault();
    });

    bind_mail_confirmation();
    bind_mail_invitation();
    bind_ulock_participant_data();
    bind_remove_test();
  };

  $('a#upload_info').on('click', function(e){
    $('#upload_info_text').toggle();
    e.preventDefault();
  });

  $('a#next_step_info').on('click', function(e){
    $('#next_step_info_text').toggle();
    e.preventDefault();
  });

  $('a.upload-participant-files').on('click', function(e){
    $('#uploadParticipantFiles').modal('show').find('.modal-body').load($(this).attr('href'), function (){
      $('form').on('ajax:success', function(e){
        $('#uploadParticipantFiles').modal('toggle');
        location.reload();
      }).on('ajax:error',function(e){
        const [data, status, xhr] = e.detail;
        $('#submit-errors').text(data.error);
      });

      $('a.delete_attachment').on('click', function(e) {
        e.preventDefault();
        var link = $(this);
        var check = confirm(link.data('confirmation'));
        if (check == true) {
          $.post({
            url: "/client/delete_attachment",
            data: "id="+$(this).data('id')+"&attachment=" + $(this).data('attachment'),
            beforeSend: function(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function(data) {
              $('.'+$(link).data('attachment')+'_attachment').remove()
            },
            error: function(data) {
            }
          });
        }
      });
    });
    e.preventDefault();
  });

  $('a.presence_confirmation').unbind('ajax:success').bind('ajax:success', function(e) {
    var state_obj = $(this).parent().find('.presence-state');
    if ($(this).attr('id') == 'present_link') {
      state_obj.html('<span class="badge badge-success">obecny</span>');
    } else {
      state_obj.html('<span class="badge badge-danger">nieobecny</span>');
    }
  });

  $(".copy-url-to-clipboard").on('click', function(e) {
    e.preventDefault();
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = $(this).attr('href');
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    $(this).fadeOut('fast').fadeIn('fast');
  });

  function CountDownTimer(duration, granularity) {
    this.duration = duration;
    this.granularity = granularity || 1000;
    this.tickFtns = [];
    this.running = false;
  }

  CountDownTimer.prototype.start = function() {
    if (this.running) {
      return;
    }
    this.running = true;
    var start = Date.now(),
        that = this,
        diff, obj;

    (function timer() {
      diff = that.duration - (((Date.now() - start) / 1000) | 0);

      if (diff > 0) {
        setTimeout(timer, that.granularity);
      } else {
        diff = 0;
        that.running = false;
      }

      obj = CountDownTimer.parse(diff);
      that.tickFtns.forEach(function(ftn) {
        ftn.call(this, obj.minutes, obj.seconds);
      }, that);
    }());
  };

  CountDownTimer.prototype.onTick = function(ftn) {
    if (typeof ftn === 'function') {
      this.tickFtns.push(ftn);
    }
    return this;
  };

  CountDownTimer.prototype.expired = function() {
    return !this.running;
  };

  CountDownTimer.parse = function(seconds) {
    return {
      'minutes': (seconds / 60) | 0,
      'seconds': (seconds % 60) | 0
    };
  };

  function timer_format(minutes, seconds) {
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    $("#competence-test-timer").html("<b>" + minutes + ':' + seconds + "</b>");
  }
  function timer_finish(minutes, seconds) {
    if (minutes == 0 && seconds == 0) {
      $("#competence-test-timer").text('czas minął!');
      setTimeout( function() { location.reload(); }, 1000);
    }
  }
  if ($("#competence-test-timer").length){
    var time_left = $("#competence-test-timer").data('timeLeft');
    var timer = new CountDownTimer(time_left);
    timer.onTick(timer_format).onTick(timer_finish).start();
  }

  $("#participant_datum_disability_1").on('change', function(e) {
    if ($(this).is(':checked')) {
      $('#disability-comment').show();
    }
  });

  $("#participant_datum_disability_2").on('change', function (e) {
    if ($(this).is(':checked')){
      $('#disability-comment').hide();
    }
  });

  $("#participant_datum_disability_3").on('change', function (e) {
    if ($(this).is(':checked')){
      $('#disability-comment').hide();
    }
  });
  $("#participant_datum_disability_1").change();

  $("#participant_datum_catering_needs_1").on('change', function(e) {
    if ($(this).is(':checked')) {
      $('#catering-comment').show();
    }
  });

  $("#participant_datum_catering_needs_2").on('change', function (e) {
    if ($(this).is(':checked')){
      $('#catering-comment').hide();
    }
  });
  $("#participant_datum_catering_needs_1").change();

  $('a.send_competence_test').unbind('ajax:success').bind('ajax:success', function(e) {
    $(this).parent().append('<span>&nbsp; Zaproszenia do testu zostały wysłane</span>');
  });

  $('a.send_group_evaluation').unbind('ajax:success').bind('ajax:success', function(e) {
    $(this).parent().append('<span>&nbsp; Zaproszenia do ankiety zostały wysłane</span>');
  });

  $("#option_1").on('change', function(e) {
    if ($(this).is(':checked')) {
      $('#option_block_1').show();
      $('#option_block_2').hide();
      $('#option_block_3').hide();
    }
  });

  $("#option_2").on('change', function(e) {
    if ($(this).is(':checked')) {
      $('#option_block_2').show();
      $('#option_block_1').hide();
      $('#option_block_3').hide();
    }
  });

  $("#option_3").on('change', function(e) {
    if ($(this).is(':checked')) {
      $('#option_block_3').show();
      $('#option_block_1').hide();
      $('#option_block_2').hide();
    }
  });

  $("#option_1").change();
  $("#option_2").change();
  $("#option_3").change();

  $('#participant_datum_address_attributes_voivodeship_sym').on('change', function(e){
    $("#participant_datum_address_attributes_city_sym").val('').trigger('change');
    $("#participant_datum_address_attributes_street_sym").val('').trigger('change');
  })

  $('#participant_datum_address_attributes_city_sym').on('change', function(e){
    $("#participant_datum_address_attributes_street_sym").val('').trigger('change');
  })

  $("#participant_datum_address_attributes_city_sym").select2({
    width : '100%',
    theme: "bootstrap",
    language: "pl",
    minimumInputLength: 2,
    ajax: {
      url: $('#participant_datum_address_attributes_city_sym').data('url'),
      dataType: 'json',
      data: function (params) {
        return {
          voivodeship: $('#participant_datum_address_attributes_voivodeship_sym').val(),
          term: params.term,
          access_token: $('#access_token').val()
        }
      },
      processResults: function (data, page) {
        return {
          results: data.results,
          more: false
        }
      }
    },
    escapeMarkup: function (markup) { return markup },
    templateResult: function (data) { return data.html },
    templateSelection: function (data) { return data.text }
  })

  if ($('#participant_datum_address_attributes_city_sym').data('id') && $('#participant_datum_address_attributes_city_sym').data('name')) {
    var city_sym = $('#participant_datum_address_attributes_city_sym').data('id');
    var city_name = $('#participant_datum_address_attributes_city_sym').data('name');
    var option = $('<option selected>' + city_name + '</option>').val(city_sym);
    $("#participant_datum_address_attributes_city_sym").append(option).trigger('change');
  }

  $("#participant_datum_address_attributes_street_sym").select2({
    width : '100%',
    theme: "bootstrap",
    language: "pl",
    minimumInputLength: 2,
    ajax: {
      url: $('#participant_datum_address_attributes_street_sym').data('url'),
      dataType: 'json',
      data: function (params) {
        return {
          city: $('#participant_datum_address_attributes_city_sym').val(),
          term: params.term,
          access_token: $('#access_token').val()
        }
      },
      processResults: function (data, page) {
        return {
          results: data.results,
          more: false
        }
      }
    }
  })

  if ($('#participant_datum_address_attributes_street_sym').data('id') && $('#participant_datum_address_attributes_street_sym').data('name')){
    var street_sym = $('#participant_datum_address_attributes_street_sym').data('id');
    var street_name = $('#participant_datum_address_attributes_street_sym').data('name');
    var option = $('<option selected>' + street_name + '</option>').val(street_sym);
    $("#participant_datum_address_attributes_street_sym").append(option).trigger('change');
  }

  // institution

  $('#participant_datum_institution_address_attributes_voivodeship_sym').on('change', function(e){
    $("#participant_datum_institution_address_attributes_city_sym").val('').trigger('change');
    $("#participant_datum_institution_address_attributes_street_sym").val('').trigger('change');
  })

  $('#participant_datum_institution_address_attributes_city_sym').on('change', function(e){
    $("#participant_datum_institution_address_attributes_street_sym").val('').trigger('change');
  })

  $("#participant_datum_institution_address_attributes_city_sym").select2({
    width : '100%',
    theme: "bootstrap",
    language: "pl",
    minimumInputLength: 2,
    ajax: {
      url: $('#participant_datum_institution_address_attributes_city_sym').data('url'),
      dataType: 'json',
      data: function (params) {
        return {
          voivodeship: $('#participant_datum_institution_address_attributes_voivodeship_sym').val(),
          term: params.term,
          access_token: $('#access_token').val()
        }
      },
      processResults: function (data, page) {
        return {
          results: data.results,
          more: false
        }
      }
    },
    escapeMarkup: function (markup) { return markup },
    templateResult: function (data) { return data.html },
    templateSelection: function (data) { return data.text }
  })

  if ($('#participant_datum_institution_address_attributes_city_sym').data('id') && $('#participant_datum_institution_address_attributes_city_sym').data('name')) {
    var city_sym = $('#participant_datum_institution_address_attributes_city_sym').data('id');
    var city_name = $('#participant_datum_institution_address_attributes_city_sym').data('name');
    var option = $('<option selected>' + city_name + '</option>').val(city_sym);
    $("#participant_datum_institution_address_attributes_city_sym").append(option).trigger('change');
  }

  $("#participant_datum_institution_address_attributes_street_sym").select2({
    width : '100%',
    theme: "bootstrap",
    language: "pl",
    minimumInputLength: 2,
    ajax: {
      url: $('#participant_datum_institution_address_attributes_street_sym').data('url'),
      dataType: 'json',
      data: function (params) {
        return {
          city: $('#participant_datum_institution_address_attributes_city_sym').val(),
          term: params.term,
          access_token: $('#access_token').val()
        }
      },
      processResults: function (data, page) {
        return {
          results: data.results,
          more: false
        }
      }
    }
  })

  if ($('#participant_datum_institution_address_attributes_street_sym').data('id') && $('#participant_datum_institution_address_attributes_street_sym').data('name')){
    var street_sym = $('#participant_datum_institution_address_attributes_street_sym').data('id');
    var street_name = $('#participant_datum_institution_address_attributes_street_sym').data('name');
    var option = $('<option selected>' + street_name + '</option>').val(street_sym);
    $("#participant_datum_institution_address_attributes_street_sym").append(option).trigger('change');
  }


  function limit_answers(){
    var n = $("input[type='checkbox']:checked").length;
    if (n > 2) {
      return false;
    }
    return true;
  }

  $('.checkbox-list input').on('click', function (e) {
    return limit_answers();
  });

  $("#participant_datum_position").on('change', function(e) {
    console.log($(this).val());
    if ($(this).val() == 'urzędnik administracyjny') {
      $('#position-description').show();
    } else {
      $('#position-description').hide();
    }
  });
  $("#participant_datum_position").change();
});
